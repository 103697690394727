<template>
	<div>
		<pui-datatable :modelName="modelName" :modelColumnDefs="modelColumnDefs" :actions="actions" :navigableDetail="false"></pui-datatable>
	</div>
</template>

<script>
import SubscriptionStopActions from './SubscriptionStopActions.js';

export default {
	name: 'subscriptionstop-grid',
	components: {},
	data() {
		return {
			modelName: 'subscriptionstop',
			actions: SubscriptionStopActions.gridactions,

			modelColumnDefs: {
				cancelled: {
					render: (data, type, row) => {
						return row.cancelled ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				waiting: {
					render: (data, type, row) => {
						return row.waiting ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				stopata: {
					render: (data, type, row) => {
						return row.stopata ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				stopfcl: {
					render: (data, type, row) => {
						return row.stopfcl ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				stoplcl: {
					render: (data, type, row) => {
						return row.stoplcl ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				stopatd: {
					render: (data, type, row) => {
						return row.stopatd ? `<i class="fas fa-check state-check"></i>` : '';
					}
				}
			}
		};
	}
};
</script>
